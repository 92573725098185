import * as React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import Page from "./Page";
import {Helmet} from "react-helmet";
import {
    Button, Checkbox, Flex, Link, Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from "@chakra-ui/react";
import {Fragment, useEffect, useState} from "react";
import Cookies from 'js-cookie';

export default function App() {
    return (
        <div>
            <Helmet>
                <script>
                {`
                    kofiWidgetOverlay.draw('anniemaymay', {
                        'type': 'floating-chat',
                        'floating-chat.donateButton.text': 'Tip Me',
                        'floating-chat.donateButton.background-color': '#ff38b8',
                        'floating-chat.donateButton.text-color': '#fff'
                    });
                `}
                </script>
            </Helmet>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Page />} />
                    <Route path="custom" element={<Custom />} />
                    <Route path="*" element={<Page />} />
                </Route>
            </Routes>
        </div>
    );
}

function Layout() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [rememberMe, setRememberMe] = useState(false);

    useEffect(() => {
        const ageVerified = Cookies.get('ageVerified');
        if (!ageVerified) {
            onOpen();
        }
    }, [onOpen]);

    const handleClose = () => {
        if (rememberMe) {
            Cookies.set('ageVerified', 'true', { expires: 365 });
        }
        onClose();
    };
    return (
        <Fragment>
            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose} size="full" motionPreset='slideInBottom'>
                <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(90deg)' />
                <ModalContent textAlign={'center'} justifySelf={'center'}>
                    <ModalHeader>Are you 18 or older?</ModalHeader>

                    <ModalFooter justifyContent={'center'}>
                        <Flex direction={'column'} align={'center'} gap={8}>
                            <Flex>
                                <Button colorScheme='blue' mr={3} onClick={handleClose}>
                                    Yes, let me in
                                </Button>
                                <Button variant='ghost'>
                                    <Link href='https://www.google.com/search?q=kids+movies'>
                                        I am not of legal age
                                    </Link>
                                </Button>
                            </Flex>
                            <Checkbox isChecked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)}>
                                Remember me
                            </Checkbox>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {!isOpen && <Outlet />}
        </Fragment>
    );
}

function Custom() {
    window.location.href = 'https://www.manyvids.com/Custom-vid/1005081534/';
    return null;
}