import React, {useEffect, useState} from 'react';
import './App.css';
import { Box } from "@chakra-ui/react";

function Page() {
    const [isLive, setIsLive] = useState(false)
    useEffect(() => {
        fetch("https://cors-anywhere-2022.herokuapp.com/https://chaturbate.com/affiliates/api/onlinerooms/?format=json&wm=jcsEW")
            .then(res => res.json())
            .then(
                (result: any) => {
                    setIsLive(result.find((item: { username: string; }) => item.username === 'annie_may_may'))
                }
            )
    },[]);
    return (
        <Box className={'app'}>
            <h1>
                Annie May
            </h1>
            <div className="links-container"><a href="https://instagram.com/annie_may_may" target="_blank" rel="noreferrer">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" x="0px" y="0px"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"></path>
                </svg>
                annie_may_may</a><a href="https://x.com/annie_may_may" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="1em" width="1em" viewBox="0 0 30 24" fill="currentColor">
                    <path
                        d="M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z"></path>
                </svg>
                annie_may_may</a><a href="https://fansly.com/annie_may" target="_blank" rel="noreferrer">
                <img src="/fansly.png" alt="F"/>
                Fansly ($5/month)</a><a href="https://bit.ly/39J0wjx" target="_blank" rel="noreferrer">
                <img src="/chaturbate.png" alt="C"/>
                Chaturbate {isLive ? <p style={{color: '#6aff9c'}}>&nbsp;LIVE NOW</p> : null}</a><a href={isLive ? "https://www.myfreecams.com/#Annie_may_" : "https://profiles.myfreecams.com/Annie_may_"} target="_blank" rel="noreferrer">
                <img src="/myfreecams.png" alt="M"/>
                MyFreeCams {isLive ? <p style={{color: '#6aff9c'}}>&nbsp;LIVE NOW</p> : null}</a><a href="https://annie_may_may.manyvids.com" target="_blank" rel="noreferrer">
                <img src="/manyvids.png" alt="MV"/>
                ManyVids</a><a href={"https://www.manyvids.com/Profile/1005081534/annie_may_may/Store/Custom"} target="_blank" rel="noreferrer">
                <img src="/manyvids.png" alt="M"/>
                Order a Custom Video</a><a href="https://share.myfreecams.com/Annie_may_" target="_blank" rel="noreferrer">
                <img src="/myfreecams.png" alt="M"/>
                MFC Share</a><a href="https://www.reddit.com/user/annie_may_may" target="_blank" rel="noreferrer">
                <img src="/reddit.png" alt="R"/>
                reddit</a><a href="https://www.redgifs.com/users/anniemaymay" target="_blank" rel="noreferrer">
                <img src="/redgifs.png" alt="RG"/>
                RedGifs</a></div>
        </Box>
    );
}

export default Page;
